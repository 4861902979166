/* RC Table */
.rc-table {
  @apply border-separate shadow-sm;

  border-spacing: 0;
}

.rc-table .rc-table-content,
.rc-table .rc-table-body,
.rc-table .rc-table-header {
  @apply border-0;
}

.rc-table tr th {
  @apply border-0 border-b;
}

.rc-table tr td {
  @apply border-0;
}

.rc-table thead.rc-table-thead th {
  @apply bg-[#F2F2F2] py-3 px-4 font-bold text-sm;
}

.rc-table tr.rc-table-row:hover {
  background-color: inherit;
}

.rc-table tr.rc-table-row td {
  @apply py-3 px-4 text-base font-normal;
}

.rc-table tr.rc-table-row:not(.rc-table-expanded-row) {
  cursor: pointer;
}

.rc-table tr.rc-table-expanded-row > td {
  @apply p-0 whitespace-normal border border-[#F7F7F7];
}

.rc-table tr.rc-table-expanded-row tr.rc-table-row {
  cursor: default;
}

.rc-table .rc-table-expanded-row-fixed::after {
  border-width: 0;
}

.rc-table-cell.rc-table-cell-row-hover {
  @apply !bg-[#F7F7F7];
}

/* Swiper hide Disabled button */
.swiper-button-disabled {
  display: none !important;
}

/* Product Single Page thumb slider style */
.product-gallery .swiper-thumbs .swiper-slide-thumb-active {
  @apply border;
}

.product-gallery .swiper-button-disabled {
  @apply opacity-0 transition-opacity duration-200;
}

/* Swiper Pagination */
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  @apply bottom-4 flex justify-center items-center space-x-2;
}

/* some utilities that are not been supported yet in Tailwind v2 */
.use-blur-backdrop {
  backdrop-filter: blur(8px);
}

/* RC Collapse */
.rc-collapse {
  background-color: #fff !important;
  border-radius: 0 !important;
  border: 0 solid #d9d9d9 !important;
}

.rc-collapse:focus {
  outline: none !important;
}

.rc-collapse > .rc-collapse-item {
  border: 0 solid #d9d9d9 !important;
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header {
  padding: 0 !important;
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header:focus {
  outline: none !important;
}

.rc-collapse-content {
  padding: 0 !important;
}

/*
Overlay Scrollbar - os-theme-thin-dark
*/
.os-viewport {
  -webkit-overflow-scrolling: touch;
}

.os-theme-thin-dark > .os-scrollbar-horizontal {
  right: 14px;
  height: 14px;
  padding: 0 6px;
}

.os-theme-thin-dark > .os-scrollbar-vertical {
  bottom: 14px;
  width: 14px;
  padding: 6px 0;
}

.os-theme-thin-dark.os-host-rtl > .os-scrollbar-horizontal {
  left: 14px;
  right: 0;
}

.os-theme-thin-dark > .os-scrollbar-corner {
  height: 14px;
  width: 14px;
  background-color: transparent;
}

.os-theme-thin-dark > .os-scrollbar > .os-scrollbar-track {
  background: transparent;
}

.os-theme-thin-dark > .os-scrollbar-horizontal > .os-scrollbar-track::before,
.os-theme-thin-dark > .os-scrollbar-vertical > .os-scrollbar-track::before {
  content: "";
  display: block;
  position: absolute;
  background: rgb(0 0 0 / 15%);
}

.os-theme-thin-dark > .os-scrollbar-horizontal > .os-scrollbar-track::before,
.os-theme-thin-dark > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle::before {
  left: 0;
  right: 0;
  height: 2px;
  top: 50%;
  margin-top: -1px;
}

.os-theme-thin-dark > .os-scrollbar-vertical > .os-scrollbar-track::before,
.os-theme-thin-dark > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle::before {
  top: 0;
  bottom: 0;
  width: 2px;
  left: 50%;
  @apply ltr:-ml-px rtl:-mr-px;
}

.os-theme-thin-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle::before {
  content: "";
  display: block;
  position: absolute;
  background: rgb(0 0 0 / 50%);
  border-radius: 10px;
}

.os-theme-thin-dark > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:hover::before,
.os-theme-thin-dark > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle.active::before {
  height: 4px;
  margin-top: -2px;
}

.os-theme-thin-dark > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:hover::before,
.os-theme-thin-dark > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle.active::before {
  width: 4px;
  @apply ltr:-ml-0.5 rtl:-mr-0.5;
}

.os-theme-thin-dark > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:hover::before,
.os-theme-thin-dark > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:hover::before {
  background: rgb(0 0 0 / 70%);
}

.os-theme-thin-dark > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle.active::before,
.os-theme-thin-dark > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle.active::before {
  background: #000;
}

.os-theme-thin-dark > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle {
  height: 100%;
  min-width: 30px;
}

.os-theme-thin-dark > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle {
  width: 100%;
  min-height: 30px;
}

.os-theme-thin-dark.os-host-transition > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle::before {
  transition: height 0.3s, margin-top 0.3s, background 0.2s;
}

.os-theme-thin-dark.os-host-transition > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle::before {
  transition: width 0.3s, margin-left 0.3s, margin-right 0.3s, background 0.2s;
}

/* react-phone-input-2 */
.react-tel-input .selected-flag:focus::before,
.react-tel-input .selected-flag.open::before {
  border-color: transparent !important;
  box-shadow: none !important;
}

body[dir="rtl"] .react-tel-input .selected-flag {
  padding-right: 11px !important;
}

body[dir="rtl"] .react-tel-input .selected-flag .arrow {
  right: 29px;
}

body[dir="rtl"] .react-tel-input .country-list .country {
  display: flex;
  padding: 12px 46px 13px 9px;
}

body[dir="rtl"] .react-tel-input .country-list .country-name {
  margin-right: 0;
  margin-left: 6px;
}

body[dir="rtl"] .react-tel-input .country-list .flag {
  margin-left: 7px;
  margin-right: 0;
  right: 13px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes heart-beat {
  55% {
    background-color: #fff;
  }

  60% {
    background-color: #00d2a8;
  }

  65% {
    background-color: #fff;
  }

  70% {
    background-color: #00d2a8;
  }

  100% {
    background-color: #fff;
  }
}

/* Reset Author Slide width */
.author-slide.swiper-slide {
  width: auto;
}

.radio-input + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  display: inline-flex;
  align-items: center;
}

.radio-input + label::before {
  content: "";
  display: inline-flex;
  width: 18px;
  height: 18px;
  border-radius: 99999px;
  background-color: #fff;
  @apply ltr:mr-2.5 rtl:ml-2.5;
}

.radio-input + label.primary::before {
  border: 1px solid rgb(209 213 219);
}

.radio-input + label.secondary::before {
  border: 1px solid #727272;
}

/* Checkbox */
.checkbox {
  position: absolute;
  opacity: 0;
}

.checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  display: inline-flex;
  align-items: center;
}

.checkbox + label::before {
  content: "";
  display: inline-flex;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  background-color: #fff;
  @apply ltr:mr-2.5 rtl:ml-2.5;
}

.checkbox + label.primary::before {
  border: 1px solid rgb(209 213 219);
}

.checkbox + label.secondary::before {
  border: 1px solid rgb(75 85 99);
}

.checkbox:focus + label.primary::before {
  border-color: rgb(0 159 127);
}

.checkbox:focus + label.secondary::before {
  border-color: rgb(31 41 55);
}

.checkbox:checked + label.primary::before {
  background-color: rgb(0 159 127);
  border-color: rgb(0 159 127);
}

.checkbox:checked + label.secondary::before {
  background-color: rgb(31 41 55);
  border-color: rgb(31 41 55);
}

.boxed-checkbox + label {
  position: relative;
  height: 100%;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  padding: 7px 16px;
  border-radius: 3px;
  background-color: rgb(249 250 251);
  border: 2px solid rgb(243 244 246);
}

.checkbox:disabled + label {
  color: rgb(107 114 128);
  cursor: auto;
}

.checkbox:disabled + label::before {
  box-shadow: none;
  background: rgb(209 213 219);
}

.checkbox:checked + label::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 50%;
  margin-top: -1px;
  background: #fff;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff, 4px -4px 0 #fff, 4px -6px 0 #fff, 4px -8px 0 #fff;
  transform: rotate(45deg);
}

/* Boxed Checkbox */
.boxed-checkbox {
  position: absolute;
  opacity: 0;
}

.boxed-checkbox:focus + label {
  border-color: rgb(229 231 235);
}

.boxed-checkbox:checked + label {
  background-color: #fff;
  color: rgb(0 159 127);
  border-color: rgb(0 159 127);
  border-width: 2px;
}

.boxed-checkbox:disabled + label {
  cursor: auto;
  background: rgb(209 213 219);
}

/* Radio */
.radio-input {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  display: none;
}

.radio-input:focus + label.primary::before {
  border-color: rgb(0 159 127);
}

.radio-input:focus + label.secondary::before {
  border-color: rgb(31 41 55);
}

.radio-input:checked + label::before {
  border-width: 5px;
}

.radio-input:checked + label.primary::before {
  border-color: rgb(0 159 127);
}

.radio-input:checked + label.secondary::before {
  border-color: rgb(31 41 55);
}

.radio-input:disabled + label {
  color: rgb(107 114 128);
  cursor: auto;
}

.radio-input:disabled + label::before {
  box-shadow: none;
  background: rgb(209 213 219);
  border: 1px solid rgb(249 250 250);
}

/* .radio-input:checked + label:after {
  content: '';
  position: absolute;
  left: 4px;
  top: 9px;
  background: #ffffff;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 #ffffff, 4px 0 0 #ffffff, 4px -2px 0 #ffffff,
    4px -4px 0 #ffffff, 4px -6px 0 #ffffff, 4px -8px 0 #ffffff;
  transform: rotate(45deg);
} */

/*
* RC Slider
*/
.rc-slider .rc-slider-track {
  background-color: rgb(55 65 81);
}

.rc-slider .rc-slider-handle {
  background-color: rgb(31 41 55);
  border: 1px solid rgb(31 41 55);
  box-shadow: 0 0 0 3px #fff inset;
  height: 20px;
  width: 20px;
  margin-top: -8px;
}

.rc-slider .rc-slider-handle:hover {
  background-color: rgb(31 41 55);
  border: 1px solid rgb(31 41 55);
}

.rc-slider .rc-slider-handle:focus,
.rc-slider .rc-slider-handle-click-focused:focus {
  border-color: rgb(31 41 55);
  box-shadow: 0 0 0 3px #fff inset, 0 0 0 3px #d1d5db;
}

.rc-slider .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: rgb(31 41 55);
  box-shadow: 0 0 0 3px #fff inset, 0 0 0 5px #d1d5db;
}
