@import "./custom-plugins.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    overscroll-behavior-y: contain;
    font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
      "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
    color: #262626;
  }

  a {
    @apply no-underline;
  }

  .textarea {
    resize: none;
  }

  .scroll-invisible::-webkit-scrollbar {
    display: none;
  }

  .tui-ico-date {
    background: url("../public/icons/calendar-icon.svg") no-repeat !important;
    transform: translateY(-50%);
    width: 24px !important;
    height: 24px !important;
    margin: 0 !important;
  }

  .dot::before {
    content: "";
    display: inline-block;
    width: 3px;
    height: 3px;
    border-radius: 8px;
    margin-right: 4px;
    background-color: #d9d9d9;
    vertical-align: middle;
  }

  .section-4-dot::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 8px;
    margin-right: 7px;
    background-color: #a6a8ad;
    vertical-align: middle;
  }
}
